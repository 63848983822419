import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Collapse, Menu, MenuItem, styled } from '@mui/material';
import {
  ArrowSquareOut,
  CaretDown,
  CaretUp,
  SignOut,
  User,
} from 'phosphor-react';
import './NavBar.scss';
import logo_white from '../../assets/images/logo-white.png';
import authService from '../../services/AuthService';
import useAuthContext from '../../hooks/UseAuthContext';
import useLoadingContext from '../../hooks/UseLoadingContext';
import {
  NavButton,
  RegisterButton,
} from '../../assets/styled-components/button';
import {
  ACTIVITY_EVENTS,
  colors,
  HttpStatus,
  TIME_TO_IDLE,
} from '../../constants/Constants';
import NestedMenuItem from '../nested-menu-item/NestedMenuItem';
import useIdle from '../../hooks/useIdle';
import keycloak from '../../keycloak';

const drawerWidth = 240;

const NavMenu = styled(Menu)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.colors.appInputLabel,
}));

const NavMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const NavMenuTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  color: theme.colors.appInputLabel,
  width: '115px',
}));

const NavBar = (props) => {
  const { window } = props;
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const navbarRef = useRef(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const {
    isAuthenticated,
    setIsAuthenticated,
    setAuthProgress,
    userDetails,
    setUserDetails,
  } = useAuthContext();
  const { isLoading } = useLoadingContext();
  const loadUserSession = useCallback(async (): Promise<void> => {
    try {
      if (!localStorage.getItem('username')) {
        if (keycloak.authenticated) {
          authService.setTokens(
            {
              access_token: keycloak.token,
              refresh_token: keycloak.refreshToken,
            },
            true
          );
        }
        const response = await authService.authenticatedUser();
        if (response.status === HttpStatus.UN_AUTHORIZED) {
          setIsAuthenticated(false);
        }
        if (
          response.status === HttpStatus.UN_AVAILABLE &&
          localStorage.getItem('access_token') &&
          localStorage.getItem('username')
        ) {
          setIsAuthenticated(true);
        }
        if (response?.status === HttpStatus.OK) {
          const user = response.data;
          if (user?.username) {
            localStorage.setItem('username', user.username);
            setIsAuthenticated(!!user.username);
            setUserDetails({
              ...userDetails,
              username: user.username,
              email: user.email,
            });
          }
        }
      } else {
        setUserDetails({
          ...userDetails,
          username: localStorage.getItem('username'),
          email: jwtDecode(localStorage.getItem('access_token')).email,
        });
      }
    } catch (e) {
      setIsAuthenticated(false);
    }
    setAuthProgress(false);
  }, []);

  const { status, logoutChannel } = useIdle({
    timeToIdle: TIME_TO_IDLE,
    activityEvents: ACTIVITY_EVENTS,
    inactivityEvents: [],
  });

  useEffect(() => {
    loadUserSession();
  }, [loadUserSession]);

  const logout = () => {
    logoutChannel.postMessage('logout');
    localStorage.clear();
    setIsAuthenticated(false);
    setUserDetails({});
    if (keycloak.authenticated) {
      keycloak?.logout();
    }
    navigate('/login');
  };

  const [mobileDatasetOpen, setMobileDatasetOpen] = useState(false);
  const [
    mobileNestedRadarDatasetOpen,
    setMobileNestedRadarDatasetOpen,
  ] = useState(false);
  const [mobileNestedNwpDatasetOpen, setMobileNestedNwpDatasetOpen] = useState(
    false
  );
  useEffect(() => {
    if (status.isIdle === 'true') {
      logout();
    }
  }, [status]);

  useEffect(() => {
    logoutChannel.onmessage = (e) => {
      logout();
      logoutChannel.close();
      authService.hardNavigate('/login');
    };
  }, []);

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        sx={{ my: 2, margin: '0px', cursor: 'pointer' }}
        onClick={() => {
          handleDrawerToggle();
          navigate('/');
        }}
      >
        <img className="logo-mobile" src={logo_white} alt="Met-Eireann" />
      </Typography>
      <Divider />
      <List>
        {isAuthenticated && (
          <ListItem disablePadding sx={{ color: colors.appWhite }}>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                navigate('/your-requests');
              }}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary="Your Requests" />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton
            onClick={() => {
              setMobileDatasetOpen((prev) => !prev);
            }}
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary="Datasets" />
            {mobileDatasetOpen ? (
              <CaretUp size={18} />
            ) : (
              <CaretDown size={18} />
            )}
          </ListItemButton>
        </ListItem>
        <Collapse in={mobileDatasetOpen} timeout="auto" unmountOnExit>
          <List sx={{ backgroundColor: colors.appWhite }}>
            <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
              <ListItemButton
                sx={{ textAlign: 'center' }}
                onClick={() => {
                  handleDrawerToggle();
                  setMobileDatasetOpen((prev) => !prev);
                  navigate('/cmd');
                }}
              >
                <ListItemText primary="Cmd" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
              <ListItemButton
                onClick={() => setMobileNestedRadarDatasetOpen((prev) => !prev)}
                sx={{ textAlign: 'center' }}
              >
                <ListItemText primary="Radar" />
                {mobileNestedRadarDatasetOpen ? (
                  <CaretUp size={18} />
                ) : (
                  <CaretDown size={18} />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={mobileNestedRadarDatasetOpen}
              timeout="auto"
              unmountOnExit
            >
              <List sx={{ backgroundColor: colors.appGrey }}>
                <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    onClick={() => {
                      handleDrawerToggle();
                      setMobileNestedRadarDatasetOpen(false);
                      setMobileDatasetOpen((prev) => !prev);
                      navigate('/radar/data-store');
                    }}
                  >
                    <ListItemText secondary="Data Store" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    onClick={() => {
                      handleDrawerToggle();
                      setMobileNestedRadarDatasetOpen(false);
                      setMobileDatasetOpen((prev) => !prev);
                      navigate('/radar/near-realtime');
                    }}
                  >
                    <ListItemText secondary="Near Real Time" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
              <ListItemButton
                onClick={() => setMobileNestedNwpDatasetOpen((prev) => !prev)}
                sx={{ textAlign: 'center' }}
              >
                <ListItemText primary="Nwp" />
                {mobileNestedNwpDatasetOpen ? (
                  <CaretUp size={18} />
                ) : (
                  <CaretDown size={18} />
                )}
              </ListItemButton>
            </ListItem>
            <Collapse
              in={mobileNestedNwpDatasetOpen}
              timeout="auto"
              unmountOnExit
            >
              <List sx={{ backgroundColor: colors.appGrey }}>
                <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    onClick={() => {
                      handleDrawerToggle();
                      setMobileNestedNwpDatasetOpen(false);
                      setMobileDatasetOpen((prev) => !prev);
                      navigate('/nwp/primary-info');
                    }}
                  >
                    <ListItemText secondary="Data Store" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ color: colors.appInputLabel }}>
                  <ListItemButton
                    sx={{ textAlign: 'center' }}
                    onClick={() => {
                      handleDrawerToggle();
                      setMobileNestedNwpDatasetOpen(false);
                      setMobileDatasetOpen((prev) => !prev);
                      navigate('/nwp/near-realtime');
                    }}
                  >
                    <ListItemText secondary="Near Real Time" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </Collapse>

        <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary="Documentation" />
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary="Tutorials" />
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton
            onClick={handleDrawerToggle}
            sx={{ textAlign: 'center' }}
          >
            <ListItemText primary="About" />
          </ListItemButton>
        </ListItem>
        <a href="https://www.met.ie/" target="_blank">
          <ListItem disablePadding sx={{ color: colors.appWhite }}>
            <ListItemButton
              onClick={handleDrawerToggle}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary="Go to met.ie" />
              <ArrowSquareOut className="share-icon" size={18} />
            </ListItemButton>
          </ListItem>
        </a>
        <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton sx={{ textAlign: 'center' }}>
            {!isAuthenticated && (
              <ListItemText
                onClick={() => {
                  handleDrawerToggle();
                  navigate(`/login`);
                }}
                primary="Login"
              />
            )}
            {isAuthenticated && (
              <ListItemText
                onClick={handleDrawerToggle}
                primary={userDetails.username}
              />
            )}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ color: colors.appWhite }}>
          <ListItemButton sx={{ textAlign: 'center' }}>
            {!isAuthenticated && (
              <ListItemText
                onClick={() => {
                  handleDrawerToggle();
                  navigate(`/register`);
                }}
                primary="Register"
              />
            )}
            {isAuthenticated && (
              <ListItemText
                onClick={() => {
                  handleDrawerToggle();
                  logout();
                }}
                primary="Logout"
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navContainerStyle = {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
  };

  const appBarStyle = { backgroundColor: colors.appBlue, height: '68px' };
  const toolbarDesktopStyle = { display: { xs: 'none', md: 'flex' } };
  const toolbarMobileStyle = {
    display: { xs: 'flex', md: 'none' },
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const logoTypographyStyle = {
    cursor: 'pointer',
    maxWidth: 'max-content',
    marginRight: '25px',
    display: { xs: 'none', md: 'block' },
  };
  const logoTypographyContentStyle = {
    display: { xs: 'none', md: 'block' },
    fontSize: '12px',
  };
  const navRightTabsStyle = { display: { xs: 'none', md: 'block' } };
  const navDividerStyle = { bgcolor: colors.appNavDivider };
  const navRightAuthStyle = {
    display: { xs: 'none', md: 'block' },
    paddingLeft: '30px',
    paddingRight: '15px',
    borderLeft: `1px solid ${colors.appNavDivider}`,
  };
  const hamburgerIconStyle = { display: { xs: 'block', md: 'none' } };
  const menuIconStyle = { fontSize: '30px' };
  const drawerStyle = {
    display: { xs: 'block', md: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
      bgcolor: colors.appBlue,
    },
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const [_, setRadarAnchorEl] = useState<null | HTMLElement>(null);
  const [__, setNwpAnchorEl] = useState<null | HTMLElement>(null);
  const datasetOpen = Boolean(anchorEl);
  const profileOpen = Boolean(profileAnchorEl);

  const datasetHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const userHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setProfileAnchorEl(event.currentTarget);
  };

  const radarHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setRadarAnchorEl(event.currentTarget);
  };
  const nwpHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setNwpAnchorEl(event.currentTarget);
  };
  const profileHandleClose = () => {
    setProfileAnchorEl(null);
  };
  const radarHandleClose = () => {
    setRadarAnchorEl(null);
  };
  const nwpHandleClose = () => {
    setNwpAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    radarHandleClose();
    nwpHandleClose();
    profileHandleClose();
  };

  return (
    <Box sx={navContainerStyle} ref={navbarRef}>
      <AppBar component="nav" sx={appBarStyle}>
        <Toolbar className="nav" sx={toolbarDesktopStyle}>
          <Box className="nav-left-logo">
            <Typography
              variant="h6"
              component="div"
              sx={logoTypographyStyle}
              onClick={() => navigate('/')}
            >
              <img className="logo" src={logo_white} alt="Met-Eireann" />
            </Typography>
          </Box>

          <Box className="nav-left-content">
            <Typography sx={logoTypographyContentStyle}>
              The Irish Meteorological Service
            </Typography>
          </Box>

          <Box className="nav-right-tabs" sx={navRightTabsStyle}>
            {isAuthenticated && (
              <NavButton
                disableRipple
                underline={location.pathname.includes('your-requests') ? 1 : 0}
              >
                <span
                  role="presentation"
                  onClick={() => navigate('/your-requests')}
                  onKeyDown={() => navigate('/your-requests')}
                  style={{ cursor: 'pointer' }}
                >
                  Your Requests
                </span>
              </NavButton>
            )}

            <NavButton disableRipple underline={0}>
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                role="presentation"
                onClick={datasetHandleClick}
                onKeyDown={datasetHandleClick}
              >
                Datasets <CaretDown className="caret-down-icon" size={18} />
              </div>
            </NavButton>
            <NavMenu
              id="all-dataset-menu"
              anchorEl={anchorEl}
              open={datasetOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                onMouseLeave: handleClose,
              }}
              autoFocus={false}
              disableAutoFocus
              disableEnforceFocus
            >
              <NavMenuItem
                onClick={() => {
                  handleClose();
                  navigate('/cmd');
                }}
              >
                <NavMenuTypo>Climate Model Data</NavMenuTypo>
              </NavMenuItem>
              <NestedMenuItem
                label="Ireland Radar Data"
                parentMenuOpen={datasetOpen}
                onClick={radarHandleClick}
              >
                <NavMenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/radar/data-store');
                  }}
                >
                  <NavMenuTypo>Data Store</NavMenuTypo>
                </NavMenuItem>
                <NavMenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/radar/near-realtime');
                  }}
                >
                  <NavMenuTypo>Near Real Time</NavMenuTypo>
                </NavMenuItem>
              </NestedMenuItem>

              <NestedMenuItem
                label="NWP Data"
                parentMenuOpen={datasetOpen}
                onClick={nwpHandleClick}
              >
                <NavMenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/nwp/primary-info');
                  }}
                >
                  <NavMenuTypo>Data Store</NavMenuTypo>
                </NavMenuItem>
                <NavMenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/nwp/near-realtime');
                  }}
                >
                  <NavMenuTypo>Near Real Time</NavMenuTypo>
                </NavMenuItem>
              </NestedMenuItem>
            </NavMenu>

            <NavButton
              onClick={() => {
                navigate('/documentation');
              }}
              disableRipple
              underline={0}
            >
              <span style={{ cursor: 'pointer' }}>Documentation</span>
            </NavButton>
            {/* <NavButton disableRipple underline={0}>
              <span style={{ cursor: 'pointer' }}>Tutorials</span>
            </NavButton> */}
            <NavButton
              onClick={() => {
                navigate('/about');
              }}
              disableRipple
              underline={0}
            >
              <span style={{ cursor: 'pointer' }}>About</span>
            </NavButton>
            <a href="https://www.met.ie/" target="_blank">
              <NavButton disableRipple underline={0}>
                <span style={{ cursor: 'pointer' }}>Go to met.ie</span>{' '}
                <ArrowSquareOut
                  cursor="pointer"
                  className="share-icon"
                  size={18}
                />
              </NavButton>
            </a>
          </Box>

          <Box className="nav-right-divider">
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={navDividerStyle}
            />
          </Box>

          <Box className="nav-right-auth-wrapper">
            <Box className="nav-right-auth" sx={navRightAuthStyle}>
              {!isAuthenticated && (
                <NavButton disableRipple underline={0}>
                  <span
                    onClick={() => navigate(`/login`)}
                    onKeyDown={() => navigate(`/login`)}
                    role="presentation"
                    style={{ cursor: 'pointer' }}
                  >
                    Login
                  </span>
                </NavButton>
              )}
              {isAuthenticated && (
                <>
                  <NavButton disableRipple underline={0}>
                    <div
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={userHandleClick}
                      onKeyDown={userHandleClick}
                      role="presentation"
                    >
                      {userDetails.username}
                      <CaretDown className="caret-down-icon" size={18} />
                    </div>
                  </NavButton>
                  <NavMenu
                    id="basic-menu"
                    anchorEl={profileAnchorEl}
                    open={profileOpen}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                      onMouseLeave: handleClose,
                    }}
                    autoFocus={false}
                    disableAutoFocus
                    disableEnforceFocus
                  >
                    <NavMenuItem
                      onClick={() => {
                        handleClose();
                        navigate('/profile');
                      }}
                    >
                      <NavMenuTypo sx={{ display: 'flex' }}>
                        <User
                          style={{ marginRight: '9px' }}
                          size={16}
                          weight="bold"
                        />
                        Profile
                      </NavMenuTypo>
                    </NavMenuItem>
                    <NavMenuItem
                      onClick={() => {
                        handleClose();
                        logout();
                      }}
                    >
                      <NavMenuTypo sx={{ display: 'flex' }}>
                        <SignOut
                          style={{ marginRight: '9px' }}
                          size={16}
                          weight="bold"
                        />
                        Logout
                      </NavMenuTypo>
                    </NavMenuItem>
                  </NavMenu>
                </>
              )}
              {!isAuthenticated && (
                <RegisterButton
                  disableRipple
                  width={108}
                  onClick={() => navigate(`/register`)}
                >
                  <span style={{ cursor: 'pointer' }}>Register</span>
                </RegisterButton>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Toolbar sx={toolbarMobileStyle}>
          <Box onClick={() => navigate('/')}>
            <img className="logo" src={logo_white} alt="Met-Eireann" />
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={hamburgerIconStyle}
          >
            <MenuIcon sx={menuIconStyle} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={drawerStyle}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
        {!isLoading ? children : null}
      </Box>
    </Box>
  );
};

export default NavBar;
